
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

const client = axios.create({
  baseURL: process.env.VUE_APP_APIGW_URL,
})

@Component({})
export default class RegisterForm extends Vue {
  $refs!: {
    form: HTMLFormElement
    submit: HTMLButtonElement
  }
  showError = false
  showDialog = false
  errorMsg: string | null = null;
  errorDetail: string | null = null;

  radio = 0;
  groups = ["테스트(999)", "에이엔씨(103, A)", "성주 경찰(104, A)", "충남대(105, CH)", "LG 생활건강(106, A)", "SK 하이닉스(107, A)", "딥카디오(108, DC)"]
  orgs = ["999", "103", "104", "105", "106", "107", "108"]
  agentType = ["", "A", "A", "CH", "A", "A", "DC"]
  testType = ""

  orgDiv = '0';
  issueCount = '1';
  useUntil = '1';
  serviceDay = '28';

  listResult: string[] = [];
  listValue = "";

  clearField(): void {
    this.errorMsg = null
    this.errorDetail = null
    this.$refs.form.reset()
    this.$refs.submit.disabled = false
    this.listResult = [];

    this.clearError()
  }

  clearError(): void {
    this.checkError()
  }

  validate(): boolean {
    this.$refs.form

    return true
  }

  submit(): void {
    if (this.validate()) {
      this.$refs.submit.disabled = true

      let orgCode = this.orgs[this.radio]

      let agent: string
      if (this.radio == 0) {
        agent = this.testType!.toUpperCase()
      } else {
        agent = this.agentType[this.radio]
      }

      const data = JSON.stringify({
        orgCode: orgCode,
        divCode: parseInt(this.orgDiv),
        numberOfIssue: parseInt(this.issueCount),
        useUntil: parseInt(this.useUntil),
        serviceDay: parseInt(this.serviceDay),
        agentType: agent,
      });
      const customConfig = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      this.listValue = "";

      client.post(
          "/mg/v2/admin/license/massive", data, customConfig
      ).then((r) => {
        if (r.data) {
          console.log("received code " + JSON.stringify(r.data))

          for (let codes of r.data["data"]) {
            console.log("codes : " + JSON.stringify(codes))
            this.listValue = this.listValue + codes["sCode"] + "\n"
          }

          this.showDialog = true
        }
        this.$refs.submit.disabled = false
      }).catch((error) => {
        this.$refs.submit.disabled = false
        if (error.response) {
          if (error.response.status == 418) {
            this.checkError("Bad Request", error.response.data?.message)
            console.log(error.response.data)
          } else {
            this.checkError("오류 발생", error.response.status)
          }
        } else if (error.request) {
          console.log(error.request)
          this.checkError("서비스 응답 없음")
        } else {
          console.log(error.message)
          this.checkError("알 수 없는 오류")
        }
      })

    } else {
      this.checkError("입력 값 오류")
    }
  }

  checkError(msg: string | null = null, detail?: string): void {
    this.errorMsg = msg
    if (detail) this.errorDetail = detail
    this.showError = this.errorMsg != null
  }
}

